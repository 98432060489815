/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = url => {
  const keyValueArr = url.split('?')[1].split('&')
  let paramObj = {}
  keyValueArr.forEach(item => {
    const keyValue = item.split('=')
    paramObj[keyValue[0]] = keyValue[1]
  })
  return paramObj
}

/**
 * JSON 克隆
 * @param {Object | Json} jsonObj json对象
 * @return {Object | Json} 新的json对象
 */
export function objClone(jsonObj) {
  return JSON.parse(JSON.stringify(jsonObj))
}

/**
 * @description 生成 uuid
 * @returns
 */
export function uuid() {
  let s = []

  const hexDigits = '0123456789abcdef'

  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }

  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((parseInt(s[19]) & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  const uuid = s.join('')

  return uuid
}

/**
 *
 * @param {*} key
 * @description 从当前URL里查找指定参数
 */
export function getQuery(key) {
  const result = location.search.match(new RegExp('(\\?|&)' + key + '=(.*?)(&|$)'))
  return result && decodeURIComponent(result[2])
}

// 处理图片的src
export const imageSrc = html => {
  const result = html
    .replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, capture) {
      if (capture.indexOf('http') >= 0) {
        return `<img src=${capture} style="width:100%">`
      } else {
        return `<img src=${process.env.VUE_APP_BASE_BACK_URL + capture} style="width:100%">`
      }
    })
    .replace(/<video [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, capture) {
      if (capture.indexOf('http') >= 0) {
        return `<video src=${capture} controls="controls" width="100%" height="100%" webkit-playsinline="true" playsinline="true" x5-playsinline="true"></video>`
      } else {
        return `<video src=${process.env.VUE_APP_BASE_BACK_URL +
          capture} controls="controls" width="100%" height="100%" webkit-playsinline="true" playsinline="true" x5-playsinline="true"></video>`
      }
    })
  return result
}

export const getSimpleText = (html, keyword = '', length = 200) => {
  const reg = new RegExp('<.+?>|&nbsp;', 'g')
  let text = html.replace(reg, '')
  let index = 0
  if (keyword) {
    index = text.indexOf(keyword) > -1 ? text.indexOf(keyword) : 0
    if (index > 0) {
      const str = text.substr(0, index + 1)
      const periodIndex = str.lastIndexOf('。') > -1 ? str.lastIndexOf('。') + 1 : 0
      if (index - periodIndex >= length) {
        length = index - periodIndex + keyword.length
      }
      index = periodIndex
    }
    text = text.substr(index, length)
    const replaceReg = new RegExp(keyword, 'g')
    // 高亮替换v-html值
    const replaceString = `<span class="color-primary">${keyword}</span>`
    text = text.replace(replaceReg, replaceString)
  }
  return text
}
