<template>
  <div class="container">
    <nav-bar title="使用说明"></nav-bar>
    <div class="margin-lr">
      <div v-html="info.description" class="rich-text"></div>
    </div>
  </div>
</template>

<script>
import * as settingApi from '@/api/setting'
import { imageSrc } from '@/utils/utils'

export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    settingApi.show(1).then(res => {
      res.data.description = imageSrc(res.data.description)
      this.info = res.data
    })
  },
  methods: {},
}
</script>
<style lang="stylus" scoped></style>
